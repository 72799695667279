import React, { useState } from "react";
import { Button } from "../../globalStyles";
import { FaInstagram, FaYoutube, FaTwitter } from "react-icons/fa";
import {
  FooterContainer,
  FooterSubscription,
  FooterSubText,
  FooterSubHeading,
  Form,
  FormInput,
  FooterLinksContainer,
  FooterLinksWrapper,
  FooterLinkItems,
  FooterLinkTitle,
  FooterLink,
  SocialMedia,
  SocialMediaWrap,
  SocialLogo,
  SocialLogoImg,
  WebsiteRights,
  SocialIcons,
  SocialIconLink,
  SubscriptionMessage,
} from "./Footer.elements";
import MailchimpSubscribe from "react-mailchimp-subscribe";

const url =
  "//hotmail.us1.list-manage.com/subscribe/post?u=c2e8b89c114c7c10af8e69821&id=76c85c954a";

function Footer() {
  const [emailAddress, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  return (
    <FooterContainer>
      <FooterSubscription>
        <FooterSubHeading>
          Join our exclusive membership to receive the latest news and trends
        </FooterSubHeading>
        <FooterSubText>You can unsubscribe at any time.</FooterSubText>

        <MailchimpSubscribe
          url={url}
          render={({ subscribe, status, message }) => (
            <Form
              onSubmit={(event) => {
                event.preventDefault();
                message = "";
                subscribe({
                  EMAIL: emailAddress,
                  FNAME: firstName,
                  MERGE2: lastName,
                });
                event.target.reset();
                setEmail("");
                setFirstName("");
                setLastName("");
              }}
            >
              {/* <FormInput name='first_name' type='text' placeholder='First Name' onChange={e => setFirstName(e.target.value)}/>
          <FormInput name='last_name' type='text' placeholder='Last Name' onChange={e => setLastName(e.target.value)}/> */}
              <FormInput
                name="email"
                type="email"
                placeholder="Your Email"
                onChange={(e) => setEmail(e.target.value)}
              />
              <Button fontBig>Subscribe</Button>

              <SubscriptionMessage>
                {status === "sending" && (
                  <p style={{ color: "#0652DD" }}>Sending...</p>
                )}
                {status === "error" && (
                  <p
                    style={{ color: "#ED4C67" }}
                    dangerouslySetInnerHTML={{ __html: message }}
                  />
                )}
                {status === "success" && (
                  <p style={{ color: "#009432" }}>Subscribed!</p>
                )}
              </SubscriptionMessage>
            </Form>
          )}
        />
      </FooterSubscription>
      <FooterLinksContainer>
        <FooterLinksWrapper>
          <FooterLinkItems>
            <FooterLinkTitle>About</FooterLinkTitle>
            {/* <FooterLink to='/sign-up'>How it works</FooterLink>
            <FooterLink to='/'>Testimonials</FooterLink>
            <FooterLink to='/'>Careers</FooterLink>
            <FooterLink to='/'>Investors</FooterLink> */}
            <FooterLink to="/about">About Matt</FooterLink>
            <FooterLink to="/">Contact</FooterLink>
            {/* <FooterLink to='/'>Terms of Service</FooterLink> */}
          </FooterLinkItems>
          {/* <FooterLinkItems>
            <FooterLinkTitle>Contact Us</FooterLinkTitle>
            <FooterLink to='/'>Contact</FooterLink>
            <FooterLink to='/'>Support</FooterLink>
            <FooterLink to='/'>Destinations</FooterLink>
            <FooterLink to='/'>Sponsorships</FooterLink>
          </FooterLinkItems> */}
        </FooterLinksWrapper>
        <FooterLinksWrapper>
          {/* <FooterLinkItems>
            <FooterLinkTitle>Videos</FooterLinkTitle>
            <FooterLink to='/'>Submit Video</FooterLink>
            <FooterLink to='/'>Ambassadors</FooterLink>
            <FooterLink to='/'>Agency</FooterLink>
            <FooterLink to='/'>Influencer</FooterLink>
          </FooterLinkItems> */}
          {/* <FooterLinkItems>
            <FooterLinkTitle>Social Media</FooterLinkTitle>
            <FooterLink href='https://www.instagram.com/barber_matt/'>Instagram</FooterLink>
            <FooterLink href='/'>Facebook</FooterLink>
            <FooterLink href='https://www.youtube.com/channel/UCL-i3MmtoOe0cnAwWhBObtA'>Youtube</FooterLink>
            <FooterLink href='/'>Twitter</FooterLink>
          </FooterLinkItems> */}
        </FooterLinksWrapper>
      </FooterLinksContainer>
      <SocialMedia>
        <SocialMediaWrap>
          <SocialLogo to="/">
            <SocialLogoImg
              src={require("../../images/logo.svg")}
              alt="logo-image"
            />
          </SocialLogo>
          <WebsiteRights>Barber Matt © 2021</WebsiteRights>
          <SocialIcons>
            {/* <SocialIconLink href='/' target='_blank' aria-label='Facebook'>
              <FaFacebook /> 
            </SocialIconLink>*/}
            <SocialIconLink
              href="https://www.instagram.com/barber_matt/"
              target="_blank"
              aria-label="Instagram"
            >
              <FaInstagram />
            </SocialIconLink>
            <SocialIconLink
              href={"//www.youtube.com/channel/UCL-i3MmtoOe0cnAwWhBObtA"}
              rel="noopener noreferrer"
              target="_blank"
              aria-label="Youtube"
            >
              <FaYoutube />
            </SocialIconLink>
            <SocialIconLink href="/" target="_blank" aria-label="Twitter">
              <FaTwitter />
            </SocialIconLink>
            {/* <SocialIconLink href='/' target='_blank' aria-label='LinkedIn'>
              <FaLinkedin />
            </SocialIconLink> */}
          </SocialIcons>
        </SocialMediaWrap>
      </SocialMedia>
    </FooterContainer>
  );
}

export default Footer;

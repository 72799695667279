import React from 'react'
import { InfoSection } from '../../components';
import { Helmet } from 'react-helmet';

function About() {


    const aboutObjOne = {
        primary: true,
        lightBg: false,
        lightTopLine: true,
        lightText: true,
        lightTextDesc: true,
        topLine: '',
        headline: 'Matt Johnston',
        description:
            ['Matt Johnston is a barber originally from Stockton, California who took a leap of faith and relocated to Hollywood in hopes of finding a high-end clientele. Through hard work and perseverance, he managed to come across some big name cuts such as Chris Brown, Steve Harvey, Travis Scott, Roddy Ricch, Tyron Woodley, Jeremih, and many more. Barber Matt is mainly known for his fades, but came up in the barber game winning battle after battle. As he started gaining more and more recognition across the industry, brands such as Old Spice, Elegance, and Pacinos all had him on payroll. His love for the craft after 8 years isn’t diminishing at all. Barber Matt currently works on different sets for his clients, does home visits, and even teaches on his spare time.'],
        buttonLabel: '',
        imgStart: 'false',
        img: require('../../images/about.jpg'),
        alt: 'Vault',
        start: 'true'
    };

    return (
        <>
        <Helmet>
        <title>About - Barber Matt Johnston</title>
        <meta
          charSet="utf-8"
          name="description"
          content="Matt Johnston is a barber originally from Stockton, California who took a leap of faith and relocated to Hollywood in hopes of finding a high-end clientele."
        />
        <link rel="canonical" href="https://barbermatt.co"/>
        </Helmet>
            <InfoSection {...aboutObjOne} />
        </>
    );
}

export default About

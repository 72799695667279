import React from "react";
import { homeObjOne, heroObj } from "./Data";
import {
  HeroSection,
  InfoSection,
  PhotoTiles,
  Pricing,
} from "../../components";
import { Helmet } from "react-helmet";

function Home() {
  return (
    <>
      <Helmet>
        <title>Home - Barber Matt Johnston</title>
        <meta
          charSet="utf-8"
          name="description"
          content="Matt Johnston is a barber originally from Stockton, California who took a leap of faith and relocated to Hollywood in hopes of finding a high-end clientele."
        />
        <link rel="canonical" href="https://barbermatt.co"/>
      </Helmet>
      <HeroSection {...heroObj} />
      <InfoSection {...homeObjOne} />
      <PhotoTiles />
      {/* <Pricing />  */}
      {/* <InfoSection {...homeObjFour} /> */}
    </>
  );
}

export default Home;
